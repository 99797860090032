// necessary set
import React from 'react'
import { observer } from 'mobx-react'

// need style
import '../main.scss'
import { getBoardNavigation } from '../../board/Board'

import PopupContainer from '@src/pages/popup/PopupContainer'
import { SectionFull } from '@component/section/section-full'
import { SectionPart } from '@component/section/section-part'

/**
 * @author kurt-cardo
 * @description
 * Main Container
 */
const MainContainer: React.FC = observer((props) => {
  return (
    <div className="content">
      <div className="main-content">
        <PopupContainer pageName={'popup'} />

        <SectionFull classes={['b-black-bg']}>
          <SectionPart>
            <div className="cardo-noti">
              <span className="noti-tit">공지사항</span>
              {getBoardNavigation()}
            </div>
          </SectionPart>
        </SectionFull>

        <SectionFull classes={['main-door']} style={{ backgroundColor: '#000' }}>
          <SectionPart>
            <div className="door-box">
              <p className="tit">THE VALUE OF DIGITAL ASSETS</p>
              <p className="txt">더 높은 디지털자산의 가치를 위해 한국디지털자산수탁(KDAC)이 함께합니다.</p>
              <p className="btn">
                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSfs9hUa8DHIaJROi50ST4iRPJ-TgIm8YOW5bAnAsPUOb77cCw/viewform"
                  target="_blank"
                  rel="noreferrer"
                >
                  KDAC(케이닥) 문의하기
                </a>
              </p>
            </div>
          </SectionPart>
        </SectionFull>

        <div className="main-contents">
          <SectionFull>
            <SectionPart>
              <div className="main-info">
                <div className="tit-img-box">
                  <div className="hot-img"></div>
                </div>
                <div className="custody-info">
                  <p className="sub-logo"></p>
                  <p className="tit">커스터디</p>
                  <p className="txt">안전하고 신뢰할 수 있는 보관 시스템</p>
                  <p className="btn">
                    <a href="/Custody">서비스 바로가기</a>
                  </p>
                </div>
              </div>
            </SectionPart>
          </SectionFull>

          <SectionFull>
            <SectionPart>
              <div className="main-info pt-30">
                <div className="custody-info pt-30">
                  <p className="sub-logo"></p>
                  <p className="tit">
                    KDAC Enterprise <br />
                    Solutions
                  </p>
                  <p className="txt">KDAC만의 블록체인 기술 노하우를 바탕으로 한 기업용 블록체인 솔루션</p>
                  <p className="btn">
                    <a href="/Solution">서비스 바로가기</a>
                  </p>
                </div>
                <div className="tit-img-box">
                  <div className="m-img"></div>
                </div>
              </div>
            </SectionPart>
          </SectionFull>
          <SectionFull>
            <SectionPart>
              <div className="main-info">
                <div className="tit-img-box">
                  <div className="cert-box">
                    <div className="img-box fiu"></div>
                    가상자산사업자 신고수리 완료
                  </div>
                  <div className="cert-box">
                    <div className="img-box isms"></div>
                    ISMS 인증 완료
                  </div>
                  <div className="cert-box">
                    <div className="img-box soc"></div>
                    SOC 1 TYPE 2 인증 완료
                  </div>
                </div>
                <div className="custody-info">
                  <p className="sub-logo"></p>
                  <p className="tit">
                    국내 주요 <br />
                    보안 인증 획득
                  </p>
                  <p className="txt">
                    국내에서 획득한 보안 시스템으로
                    <br />
                    자산을 안전하게 보호합니다.
                  </p>
                </div>
              </div>
            </SectionPart>
          </SectionFull>
          <SectionFull>
            <SectionPart>
              <div className="main-info">
                <div className="custody-info">
                  <div className="logo-box">
                    <p className="sub-logo"></p>
                  </div>
                  <p className="tit">
                    가상자산사업자 <br />
                    배상책임보험 가입
                  </p>
                  <p className="txt">
                    삼성화재 배상책임보험 가입으로
                    <br />
                    고객 자산관리의 안전성을 높였습니다.
                  </p>
                </div>
                <div className="tit-img-box">
                  <div className="insurance-img"></div>
                  {/*<div className="insurance-box">*/}
                  {/*  <div className="insurance-img"></div>*/}
                  {/*</div>*/}
                </div>
              </div>
            </SectionPart>
          </SectionFull>
        </div>
        {/*<div className="section-full b-bg">*/}
        {/*  <div className="section-part" style={{ '--sectionWidth': '1280px', height: '600px' } as React.CSSProperties}>*/}
        {/*    <div className="main-info info2">*/}
        {/*      <div className="custody-info info3">*/}
        {/*        <p className="sub-logo"></p>*/}
        {/*        <p className="tit">KDAC Enterprise Solutions</p>*/}
        {/*        <p className="txt">KDAC만의 블록체인 기술 노하우를 바탕으로 한 기업용 블록체인 솔루션</p>*/}
        {/*        <p className="btn">*/}
        {/*          <a href="/Solution">서비스 바로가기</a>*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*      <div className="tit-img-box">*/}
        {/*        <div className="m-img"></div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className="section-full">*/}
        {/*  <div className="section-part" style={{ '--sectionWidth': '1280px' } as React.CSSProperties}>*/}
        {/*    <div className="main-info">*/}
        {/*      <div className="custody-info info6">*/}
        {/*        <p className="sub-logo"></p>*/}
        {/*        <p className="tit">*/}
        {/*          국내 주요 <br />*/}
        {/*          보안 인증 획득*/}
        {/*        </p>*/}
        {/*        <p className="txt">*/}
        {/*          국내에서 획득한 보안 시스템으로*/}
        {/*          <br />*/}
        {/*          자산을 안전하게 보호합니다.*/}
        {/*        </p>*/}
        {/*      </div>*/}
        {/*      <div className="tit-img-box">*/}
        {/*        <div className="cert-box">*/}
        {/*          <div className="img-box fiu"></div>*/}
        {/*          가상자산사업자 신고수리 완료*/}
        {/*        </div>*/}
        {/*        <div className="cert-box">*/}
        {/*          <div className="img-box isms"></div>*/}
        {/*          ISMS 인증 완료*/}
        {/*        </div>*/}
        {/*        <div className="cert-box">*/}
        {/*          <div className="img-box soc"></div>*/}
        {/*          SOC 1 TYPE 2 인증 완료*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<SectionFull classes={['b-bg']}>*/}
        {/*  <SectionPart>*/}
        {/*    <div className="main-info">*/}
        {/*      <div className="tit-img-box">*/}
        {/*        <div className="insurance-img"></div>*/}
        {/*        <div className="insurance-box">*/}
        {/*          <div className="insurance-img"></div>*/}
        {/*          /!*<div className="insurance-logo"></div>*!/*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="custody-info">*/}
        {/*        <div className="logo-box">*/}
        {/*          <p className="sub-logo"></p>*/}
        {/*          /!*<p className="insurance-logo"></p>*!/*/}
        {/*        </div>*/}
        {/*        <p className="tit">*/}
        {/*          가상자산사업자 <br />*/}
        {/*          배상책임보험 가입*/}
        {/*        </p>*/}
        {/*        <p className="txt">삼성화재 배상책임보험 가입으로 고객 자산관리의 안전성을 높였습니다.</p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </SectionPart>*/}
        {/*</SectionFull>*/}
      </div>
    </div>
  )
})

export default MainContainer
